import { FlightsView } from '../models/flights-view.model';
import { ExperienceStatus } from '../../models/experience-status.model';

export const FLIGHTS_VIEW_PAIRED_MOCK: FlightsView = {
  main: {
    outbound: {
      id: 333318,
      parentId: null,
      status: ExperienceStatus.PROPOSAL,
      title: 'From Madrid to Mallorca',
      duration: '26h 45m',
      dayDifference: 1,
      flightLegs: [
        {
          number: 'F1234',
          cabin: 'First',
          airlineName: 'Iberia',
          flightDuration: '1 2h 45m',
          layoverDuration: null,
          travelers: [],
          departure: {
            airport: {
              name: 'Adolfo Suárez Madrid Barajas Airport',
              iata: 'MAD',
            },
            datetime: '2024-02-04T12:00:00',
            terminal: 'T4',
          },
          arrival: {
            airport: {
              name: 'Palma De Mallorca Airport',
              iata: 'PMI',
            },
            terminal: 'T1',
            datetime: '2024-02-05T14:45:00',
          },
        },
      ],
      cabin: 'First',
    },
    inbound: {
      id: 333295,
      parentId: null,
      status: ExperienceStatus.PROPOSAL,
      title: 'From Mallorca to Madrid',
      duration: '1h 00m',
      dayDifference: 0,
      flightLegs: [
        {
          number: 'F9876',
          cabin: 'First',
          airlineName: 'Iberia',
          flightDuration: '1h',
          layoverDuration: null,
          travelers: [],
          departure: {
            airport: {
              name: 'Palma De Mallorca Airport',
              iata: 'PMI',
            },
            terminal: 'T1',
            datetime: '2024-02-05T12:00:00',
          },
          arrival: {
            airport: {
              name: 'Adolfo Suárez Madrid Barajas Airport',
              iata: 'MAD',
            },
            terminal: 'T4',
            datetime: '2024-02-05T13:00:00',
          },
        },
      ],
      cabin: 'First',
    },
    localPriceWithCurrency: '$0.00',
    priceWithCurrency: '$0.00',
    cabinClass: 'First',
  },
  alternatives: [
    {
      outbound: {
        id: 333323,
        parentId: 333295,
        status: ExperienceStatus.CONFIRMED,
        flightLegs: [],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: 0,
        cabin: 'Economy',
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
      cabinClass: 'Economy',
    },
    {
      outbound: {
        id: 333321,
        parentId: 333295,
        flightLegs: [
          {
            airlineName: 'Iberia',
            arrival: {
              airport: {
                name: 'John F. Kennedy International Airport',
                iata: 'JFK',
              },
              datetime: '2024-02-04T19:30:00',
              terminal: 'T1',
            },
            cabin: 'Economy',
            departure: {
              airport: {
                name: 'Adolfo Suárez Madrid Barajas Airport',
                iata: 'MAD',
              },
              datetime: '2024-02-04T08:00:00',
              terminal: 'T4',
            },
            layoverDuration: '4h 15m',
            number: 'F1234',
            flightDuration: '11h 30m',
            travelers: [],
          },
          {
            airlineName: 'American Airlines',
            arrival: {
              airport: {
                name: 'Los Angeles International Airport',
                iata: 'LAX',
              },
              datetime: '2024-02-05T06:30:00',
              terminal: 'T4',
            },
            cabin: 'Economy',
            departure: {
              airport: {
                name: 'John F. Kennedy International Airport',
                iata: 'JFK',
              },
              datetime: '2024-02-04T23:45:00',
              terminal: 'T8',
            },
            layoverDuration: '3h 30m',
            number: 'F5678',
            flightDuration: '6h 45m',
            travelers: [],
          },
          {
            airlineName: 'United Airlines',
            arrival: {
              airport: {
                name: 'Sydney Airport',
                iata: 'SYD',
              },
              datetime: '2024-02-06T23:15:00',
              terminal: 'T1',
            },
            cabin: 'Economy',
            departure: {
              airport: {
                name: 'Los Angeles International Airport',
                iata: 'LAX',
              },
              datetime: '2024-02-05T10:00:00',
              terminal: 'T7',
            },
            layoverDuration: null,
            number: 'F9012',
            flightDuration: '13h 15m',
            travelers: [],
          },
        ],
        status: ExperienceStatus.PROPOSAL,
        title: 'Alternative to flights',
        duration: null,
        dayDifference: 2,
        cabin: 'Economy',
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
      cabinClass: 'Economy',
    },
    {
      outbound: {
        id: 333324,
        parentId: 333295,
        status: ExperienceStatus.SUGGESTION,
        flightLegs: [],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: 0,
        cabin: 'Economy',
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
      cabinClass: 'Economy',
    },
    {
      outbound: {
        id: 333322,
        parentId: 333295,
        status: ExperienceStatus.CANCELED,
        flightLegs: [
          {
            number: 'F5678',
            cabin: 'Economy',
            airlineName: 'Delta Airlines',
            flightDuration: '3h 30m',
            layoverDuration: '2h 15m',
            travelers: [],
            departure: {
              airport: {
                name: 'John F. Kennedy International Airport',
                iata: 'JFK',
              },
              terminal: 'T4',
              datetime: '2024-02-05T08:00:00',
            },
            arrival: {
              airport: {
                name: "Chicago O'Hare International Airport",
                iata: 'ORD',
              },
              terminal: 'T2',
              datetime: '2024-02-05T11:30:00',
            },
          },
          {
            number: 'F9101',
            cabin: 'Economy',
            airlineName: 'United Airlines',
            flightDuration: '4h 45m',
            layoverDuration: '1h 30m',
            travelers: [],
            departure: {
              airport: {
                name: "Chicago O'Hare International Airport",
                iata: 'ORD',
              },
              terminal: 'T1',
              datetime: '2024-02-05T13:45:00',
            },
            arrival: {
              airport: {
                name: 'Denver International Airport',
                iata: 'DEN',
              },
              terminal: 'B',
              datetime: '2024-02-05T18:30:00',
            },
          },
          {
            number: 'F1122',
            cabin: 'Economy',
            airlineName: 'Southwest Airlines',
            flightDuration: '2h 45m',
            layoverDuration: null,
            travelers: [],
            departure: {
              airport: {
                name: 'Denver International Airport',
                iata: 'DEN',
              },
              terminal: 'C',
              datetime: '2024-02-05T20:00:00',
            },
            arrival: {
              airport: {
                name: 'Los Angeles International Airport',
                iata: 'LAX',
              },
              terminal: 'T1',
              datetime: '2024-02-05T22:45:00',
            },
          },
        ],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: 0,
        cabin: 'Economy',
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
      cabinClass: 'Economy',
    },
  ],
};

export const FLIGHTS_VIEW_ONE_WAY_MOCK: FlightsView = {
  main: {
    outbound: {
      id: 333295,
      parentId: null,
      status: ExperienceStatus.PROPOSAL,
      title: 'From Mallorca to Madrid',
      duration: '1h 00m',
      dayDifference: 0,
      flightLegs: [
        {
          number: 'F9876',
          cabin: 'First',
          airlineName: 'Iberia',
          flightDuration: '1h',
          layoverDuration: null,
          travelers: [],
          departure: {
            airport: {
              name: 'Palma De Mallorca Airport',
              iata: 'PMI',
            },
            terminal: 'T1',
            datetime: '2024-02-05T12:00:00',
          },
          arrival: {
            airport: {
              name: 'Adolfo Suárez Madrid Barajas Airport',
              iata: 'MAD',
            },
            terminal: 'T4',
            datetime: '2024-02-05T13:00:00',
          },
        },
      ],
      cabin: 'First',
    },
    inbound: null,
    localPriceWithCurrency: '$0.00',
    priceWithCurrency: '$0.00',
    cabinClass: 'First',
  },
  alternatives: [
    {
      outbound: {
        id: 333323,
        parentId: 333295,
        status: ExperienceStatus.CONFIRMED,
        flightLegs: [],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: 0,
        cabin: 'Economy',
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
      cabinClass: 'Economy',
    },
    {
      outbound: {
        id: 333321,
        parentId: 333295,
        flightLegs: [
          {
            airlineName: 'Iberia',
            arrival: {
              airport: {
                name: 'John F. Kennedy International Airport',
                iata: 'JFK',
              },
              datetime: '2024-02-04T19:30:00',
              terminal: 'T1',
            },
            cabin: 'Economy',
            departure: {
              airport: {
                name: 'Adolfo Suárez Madrid Barajas Airport',
                iata: 'MAD',
              },
              datetime: '2024-02-04T08:00:00',
              terminal: 'T4',
            },
            layoverDuration: '4h 15m',
            number: 'F1234',
            flightDuration: '11h 30m',
            travelers: [],
          },
          {
            airlineName: 'American Airlines',
            arrival: {
              airport: {
                name: 'Los Angeles International Airport',
                iata: 'LAX',
              },
              datetime: '2024-02-05T06:30:00',
              terminal: 'T4',
            },
            cabin: 'Economy',
            departure: {
              airport: {
                name: 'John F. Kennedy International Airport',
                iata: 'JFK',
              },
              datetime: '2024-02-04T23:45:00',
              terminal: 'T8',
            },
            layoverDuration: '3h 30m',
            number: 'F5678',
            flightDuration: '6h 45m',
            travelers: [],
          },
          {
            airlineName: 'United Airlines',
            arrival: {
              airport: {
                name: 'Sydney Airport',
                iata: 'SYD',
              },
              datetime: '2024-02-06T23:15:00',
              terminal: 'T1',
            },
            cabin: 'Economy',
            departure: {
              airport: {
                name: 'Los Angeles International Airport',
                iata: 'LAX',
              },
              datetime: '2024-02-05T10:00:00',
              terminal: 'T7',
            },
            layoverDuration: null,
            number: 'F9012',
            flightDuration: '13h 15m',
            travelers: [],
          },
        ],
        status: ExperienceStatus.PROPOSAL,
        title: 'Alternative to flights',
        duration: null,
        dayDifference: 2,
        cabin: 'Economy',
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
      cabinClass: 'Economy',
    },
    {
      outbound: {
        id: 333324,
        parentId: 333295,
        status: ExperienceStatus.SUGGESTION,
        flightLegs: [],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: 0,
        cabin: 'Economy',
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
      cabinClass: 'Economy',
    },
    {
      outbound: {
        id: 333322,
        parentId: 333295,
        status: ExperienceStatus.CANCELED,
        flightLegs: [
          {
            number: 'F5678',
            cabin: 'Economy',
            airlineName: 'Delta Airlines',
            flightDuration: '3h 30m',
            layoverDuration: '2h 15m',
            travelers: [],
            departure: {
              airport: {
                name: 'John F. Kennedy International Airport',
                iata: 'JFK',
              },
              terminal: 'T4',
              datetime: '2024-02-05T08:00:00',
            },
            arrival: {
              airport: {
                name: "Chicago O'Hare International Airport",
                iata: 'ORD',
              },
              terminal: 'T2',
              datetime: '2024-02-05T11:30:00',
            },
          },
          {
            number: 'F9101',
            cabin: 'Economy',
            airlineName: 'United Airlines',
            flightDuration: '4h 45m',
            layoverDuration: '1h 30m',
            travelers: [],
            departure: {
              airport: {
                name: "Chicago O'Hare International Airport",
                iata: 'ORD',
              },
              terminal: 'T1',
              datetime: '2024-02-05T13:45:00',
            },
            arrival: {
              airport: {
                name: 'Denver International Airport',
                iata: 'DEN',
              },
              terminal: 'B',
              datetime: '2024-02-05T18:30:00',
            },
          },
          {
            number: 'F1122',
            cabin: 'Economy',
            airlineName: 'Southwest Airlines',
            flightDuration: '2h 45m',
            layoverDuration: null,
            travelers: [],
            departure: {
              airport: {
                name: 'Denver International Airport',
                iata: 'DEN',
              },
              terminal: 'C',
              datetime: '2024-02-05T20:00:00',
            },
            arrival: {
              airport: {
                name: 'Los Angeles International Airport',
                iata: 'LAX',
              },
              terminal: 'T1',
              datetime: '2024-02-05T22:45:00',
            },
          },
        ],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: 0,
        cabin: 'Economy',
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
      cabinClass: 'Economy',
    },
  ],
};
