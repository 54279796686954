export const environment = {
  apiEnv: 'https://backoffice.essentialist.com/',
  brainDomain: 'https://brain.essentialist.com/',
  brand: 'Essentialist',
  cloudinaryURL: 'https://res.cloudinary.com/thebellhop/image/upload/',
  cloudinaryURLBase: 'res.cloudinary.com/thebellhop/image/upload/',
  cookiesDomain: '.essentialist.com',
  engineDomain: 'https://engine.essentialist.com/',
  domain: 'https://essentialist.com/',
  googleMapsApiKey: 'AIzaSyCqc5QFzrK-XCBtfNhrqQMvNdS-kgIsmUo',
  gtmApiKey: 'GTM-TCD28J9H',
  landingPage: 'https://essentialist.com',
  markerActiveUrl:
    'https://res.cloudinary.com/thebellhop/image/upload/v1650373067/experience/icons/markers/map-icon-number-active-mobile-',
  markerUrl: 'https://res.cloudinary.com/thebellhop/image/upload/v1650373067/experience/icons/markers/map-icon-number-',
  production: true,
  proposalWebsite: 'https://proposal.essentialist.com/',
  publicWebsite: 'https://essentialist.com/',
  sentryBrainDns: 'https://f6bf1127a6854e128ed6a8d8f07cf5e2@o76566.ingest.sentry.io/5179988',
  sentryEngineDns: 'https://6d40cda74bd746f99738a60c954ca1ad@o76566.ingest.sentry.io/4505488289890304',
  sentryTravelDns: 'https://2e9e6808295348f8b54c434372de370d@o76566.ingest.sentry.io/1879338',
  talkJsAppKey: '7bZjGgJN',
  travelDomain: 'https://travel.essentialist.com/',
  vgsEnvironment: 'live',
  vgsVault: 'tntm1ghb9cm',
  wsEnv: 'wss://backoffice.essentialist.com/ws/',
};
